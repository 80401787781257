.nav-link:hover {
  color: #0179cc !important;
}
.top-home-container {
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
}
.nav-custom {
  margin-right: 0.7em;
}

.header-container {
  background-color: #ffffff;
  width: 100%;
  height: 70px;
  margin: 0px;
  /* position: fixed; */
  top: 0;
  z-index: 1;
  border-bottom: 3px solid #dedede;
}
.active-item {
  font-weight: 500 !important;
  padding-bottom: 5px;
}
.nav-link {
  color: #000000;
}

.kratin-logo {
  width: 170px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 20px;
}

.menu-container {
  height: 100%;
  max-width: 985px;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.menu-items {
  float: right;
  margin: 0;
  padding: 0;
  height: 100%;
  list-style-type: none;
  display: flex;
}
.menu-items li {
  float: left;
  font-size: 18px !important;
  padding: 5px 15px;
  margin: auto 0;
}
.menu-items-container {
  float: left;
  font-size: 18px !important;
  padding: 5px 15px;
  margin: auto 0;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 30px;
}

.menu-items-container a {
  color: black;
  height: 100%;
  cursor: pointer;
  font-size: initial;
  text-decoration: none;
}

.menu-items-container a:hover {
  color: #0179cc;
}

.active-item {
  border-bottom: 3px solid #0179cc;
  color: #0179cc !important;
  font-weight: bold;
}

.mobile-menu {
  font-size: 25px;
  display: none;
  color: black;
}

.mobile-menu-container {
  background-color: #adadad;
  position: relative;
  z-index: 1;
  right: 0;
  float: right;
  width: 50%;
  top: 3px;
}
.content-box {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 750px;
  max-width: 985px;
  margin: 0 auto;
}

#mobileMenuBars {
  padding-left: 10px;
}

.mobile-item a {
  text-decoration: none;
  color: black;
}

.activeMobileItem a {
  color: #0179cc !important;
  font-weight: bold !important;
}

.roxMobile,
.roxMobile ul {
  list-style: none outside none;
  margin: 0;
}

.roxMobile ul {
  margin-top: 4px;
  padding: 0;
}

.roxMobile .tapBox {
  position: relative;
  font-size: 20px;
}

.roxMobile .tapBox:before {
  border-top: 1px solid #adadad;
  content: "\f078";
  cursor: pointer;
  font-family: fontawesome;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  height: 38px;
  line-height: 48px;
  right: 0;
  padding-bottom: 5px;
  position: absolute;
  text-align: center;
  text-decoration: inherit;
  top: 0;
  width: 40px;
}

.roxMobile .expandable-tapBox:before {
  border-top: 1px solid #adadad;
  color: #777;
  content: "\f078";
  cursor: pointer;
  font-family: fontawesome;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  height: 38px;
  line-height: 48px;
  right: 0;
  padding-bottom: 5px;
  position: absolute;
  text-align: center;
  text-decoration: inherit;
  top: 0;
  width: 40px;
}

.roxMobile .breadcrumb {
  background-color: transparent;
  border-radius: 0 0 0 0;
}

/* Add margin to the next section to make space for the fixed header */
.next-section {
  margin-top: 70px; /* Same as the header height */
}
.mobile-icon {
  width: 1.2em;
}
.mobile-icon:focus-within {
  border: none;
}

@media screen and (max-width: 1000px) {
  #navbar {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .kratin-logo {
    width: 130px;
    height: 65px;
    top: 5px;
    left: 10px;
  }
  .mobile-menu {
    display: flex;
    height: auto;
    position: relative;
    top: 0;
    margin-top: 1.2rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
  .mobile-menu {
    display: flex;
    padding: 0;
  }
  /* .navbar-img {
    height: 30px;
  } */
}
@media screen and (max-width: 479px) {
  .mobile-menu {
    display: flex;
    padding: 0;
    padding-top: 0px;
  }
  /* .navbar-img {
    height: 30px;
  } */
}
