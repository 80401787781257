* {
  box-sizing: border-box;
  outline: none;
  scroll-behavior: smooth;
}

body {
  height: auto;
  width: 100%;
  min-width: 320px;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-size: 18px;
  line-height: 1.6em;
  color: #000;
  font-weight: 100;
}

.main-container {
  margin-top: 0px;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in;
  padding-top: 0px;
  width: 100%;
  height: auto;
  position: relative;
}
.demoCard {
  display: flex;
  position: fixed;
  top: 14em;
  z-index: 1;
  right: 0;
  background-color: #0179cc;
  width: 3em;
  height: 12em;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
  transition-duration: 0.8s;
  -webkit-transition-duration: 0.4s;
}
.demoCardText {
  transform: rotate(90deg);
  color: #ffffff;
  font-size: 20px;
  text-align: left;
  font-weight: 500;
  position: fixed;
  top: 17em;
  right: -50px;
  cursor: pointer;
}

.scrollUp {
  background-color: #0179cc;
  width: 45px;
  right: 40px;
  bottom: 40px;
  position: fixed;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.scrollUp i {
  margin: auto;
  font-size: 30px;
  color: white;
}

@media (min-width: 0px) and (max-width: 1000px) {
  .scrollup {
    width: 45px;
    height: 45px;
    position: fixed;
    bottom: 10rem;
    right: 0px;
    display: none;
    background-color: #0179cc;
    border-radius: 5px;
  }
}
@media screen and (max-width: 768px) {
  .demoCard {
    position: fixed;
    top: 8em;
    height: 10em;
    right: 0;
    width: 2em;
  }

  .demoCardText {
    transform: rotate(90deg);
    color: white;
    font-size: 18px;
    position: fixed;
    top: 12em;
    right: -56px;
    cursor: pointer;
  }
}

@media screen and (width<=480px) {
  .scrollUp {
    width: 30px;
    right: 6px;
    height: 30px;
  }
  .scrollUp i {
    font-size: 20px;
  }
}
