.alliance {
  margin-top: 55px;
  margin-bottom: 80px;
}
.alliance-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 985px;
  margin: 0 auto;
}
.alliance-main-title {
  margin-top: 40px;
  font-size: 2.5em !important;
  color: #282828;
  text-align: left !important;
  font-family: "Open Sans";
  margin-left: 10px;
  margin-right: 10px;
  line-height: 1em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  padding-top: 0px;
}
.alliance-main-title.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media screen and (max-width: 768px) {
  .alliance-img {
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 350px) {
  .alliance-main-title {
    font-size: 1.7em !important;
  }
}
