.overview {
  margin: 0px;
  position: relative;
  /* top: 0;
  left: 0; */
  width: 100%;
}
#solutions {
  padding-top: 70px;
  margin-top: -70px;
}
.title-wrapper {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
}
.solution-container {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
}
.main-title {
  margin: 0;
  margin-bottom: 10px;
  margin-top: 15px;
  font-size: 2.8rem;
  font-family: "Open Sans";
  line-height: 1em;
  font-weight: 300;
  color: #333;
  letter-spacing: 2px;
  text-rendering: optimizeLegibility;
  padding-top: 0px;
}
.main-title-truliacare {
  margin: 0;
  margin-bottom: 40px;
  margin-top: 55px;
  font-size: 2.25em;
  font-weight: 300;
  color: #333;
  margin-left: 0px;
  margin-left: -8px;
}
.sub-title {
  font-weight: 500;
  color: #1e2425;
  font-size: 19px;
  margin-right: 10px;
  text-align: left;
  line-height: 1.5em;
  padding-top: 10px;
}
.ompf-portfolio-wrapper {
  overflow: hidden;
  padding: 0px;
  margin: 1em 0px;
}
.adjustContainer {
  max-width: 985px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
}
.adjust-solution-conatiner {
  max-width: 985px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 0 auto;
}
.ompf-portfolio {
  /* margin-right: -3%; */
  margin-left: 0;
  margin-right: 0;
  /* display: flex; */
}
.ompf-portfolio.ompf-sc-portfolio .ompf-portfolio-thumb {
  /* -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.37); */
  /* -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.37);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.37); */
}

.ompf-portfolio.ompf-sc-portfolio .ompf-portfolio-thumb {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}
.care-card {
  margin-right: 5px;
  width: 100%;
}
.care-card:nth-last-child() {
  margin-right: 0px;
}
.ompf-portfolio.ompf-size-small .ompf-portfolio-thumb {
  width: 90%;
  /* padding-right: 1em; */
}
.adjust-height {
  line-height: 3.5;
}
.ompf-portfolio-thumb {
  float: left;
  margin-right: 3%;
  margin-bottom: 3%;
  position: relative;
}
.om_theme .ompf-portfolio-thumb a {
  color: #666;
}

.ompf-portfolio-thumb .ompf-pic-wrapper {
  display: block;
  margin: -1px;
}

.om_theme .ompf-preview-layout-full a:hover {
  color: #fff;
  background-color: #aaa;
}

.ompf-portfolio-thumb a {
  display: block;
  border: 1px solid #ddd;
  text-decoration: none;
  /*color: #000;
color: inherit;*/
  color: #666;
  border-radius: 3px;
  /* -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2); */
  /* box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2); */
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.ompf-portfolio-thumb .ompf-pic {
  display: block;
  position: relative;
  overflow: hidden;
  background: #eee;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px 3px 0 0;
}

.ompf-portfolio-thumb .ompf-pic-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ompf-portfolio-thumb.ompf-with-link a:hover img {
  -webkit-transform: scale(1.2, 1.2) rotate(0.1deg);
  -moz-transform: scale(1.2, 1.2) rotate(0.1deg);
  transform: scale(1.2, 1.2) rotate(0.1deg);
  background-color: white;
}

.ompf-portfolio-thumb .ompf-pic img {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  border: 0;
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  transition: transform 0.6s;
}

.ompf-portfolio-thumb .ompf-desc-wrapper {
  display: block;
}

.ompf-portfolio-thumb .ompf-desc {
  display: block;
  padding: 1em;
}

.ompf-portfolio-thumb .ompf-title {
  font-size: 120%;
}

.ompf-portfolio-thumb .ompf-title {
  display: block;
  font-weight: 400;
  font-size: 0.89em;
  text-align: center;
  white-space: nowrap;
}
.ompf-preview-layout-full .ompf-portfolio-thumb a .ompf-pic:after,
.ompf-preview-layout-thumbnail .ompf-portfolio-thumb a .ompf-pic:after {
  content: "\f061";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  color: #fff;
  background: #000;
  background-color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  opacity: 0;
  border-radius: 25px;
}

.ompf-preview-layout-full .ompf-portfolio-thumb a .ompf-pic-inner:after,
.ompf-preview-layout-thumbnail .ompf-portfolio-thumb a .ompf-pic-inner:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}

.hover-image-extra .over,
.ompf-portfolio-thumb a .ompf-pic-inner:after {
  background: rgba(79, 132, 177, 0.65);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(79, 132, 177, 0.85) 0%,
    rgba(79, 132, 177, 0.1) 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(79, 132, 177, 0.85)),
    color-stop(100%, rgba(79, 132, 177, 0.1))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(79, 132, 177, 0.85) 0%,
    rgba(79, 132, 177, 0.1) 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(79, 132, 177, 0.85) 0%,
    rgba(79, 132, 177, 0.1) 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(79, 132, 177, 0.85) 0%,
    rgba(79, 132, 177, 0.1) 100%
  );
  /* background: radial-gradient(ellipse at center,rgba(79,132,177,0.85) 0%,rgba(79,132,177,0.1) 100%); */
}

.ompf-preview-layout-full .ompf-portfolio-thumb a:hover .ompf-pic-inner:after,
.ompf-preview-layout-thumbnail
  .ompf-portfolio-thumb
  a:hover
  .ompf-pic-inner:after {
  opacity: 1;
}

.ompf-preview-layout-full a:hover {
  color: #fff;
  background-color: #0179cc;
}

.ompf-preview-layout-full .ompf-portfolio-thumb a:hover .ompf-pic:after,
.ompf-preview-layout-thumbnail .ompf-portfolio-thumb a:hover .ompf-pic:after {
  opacity: 1;
  -webkit-animation: zoominback 0.5s;
  -moz-animation: zoominback 0.5s;
  animation: zoominback 0.5s;
}
.adjustHeight {
  line-height: 3.5;
}
.solution-feature-desc {
  text-align: justify;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.7em;
  font-weight: 100;
  color: black;
  letter-spacing: 0.1px;
}
.click-here {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  color: #2a6496;
  margin-left: 0.2em;
}
.card-sol {
  padding-right: 0;
}

/* .details-form button {
  display: block;
  margin: 16px auto 0;
  background-color: #0179cc;
  font-size: 18px;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 20px;
  margin-bottom: -0.5em;
  margin-top: -0.5em;
} */

.download-pdf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 1050;
}
.download-modal {
  background-color: #fff;
  position: relative;
  padding: 1em;
  width: 40%;
  border-radius: 8px;
}
.download-modal-heading {
  /* margin-bottom: 1em; */
  text-align: center;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}
.download-modal-button {
  background-color: #0179cc;
  color: #fff;
  font-size: 1.3em;
  width: 30%;
  border: 1px solid #0179cc;
  padding: 1em 0px;
  font-weight: 100 !important;
  margin-bottom: 0px !important;
  border-radius: 0px;
  margin-left: 35%;
  margin-right: 35%;
  text-align: center;
}
.download-modal-close {
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 1.2em;
  cursor: pointer;
}

.download-modal-close:hover {
  color: #bb0205;
}

@media only screen and (min-width: 560px) and (max-width: 991px) {
  .ompf-portfolio {
    /* margin-left: 3%;
    margin-right: 3%; */
  }
  .care-card {
    display: block;
  }
  .card-sol {
    margin-right: -2em;
    margin-bottom: 1em;
  }
  .adjust-height {
    line-height: 1.5;
  }
  .title-wrapper {
    /* max-width: 750px; */
  }
}
@media only screen and (max-width: 767px) {
  .overview {
    top: 28px;
  }
  .ompf-portfolio {
    display: block;
  }
}

@media screen and (max-width: 479px) {
  .overview .main-title {
    font-size: 2.1em;
    margin-bottom: 0;
    margin-top: 0;
  }
  .care-card {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .sol-title-desc {
    margin: 25px 6px !important;
  }

  .solution-feature-desc {
    line-height: 1.5em;
  }
  .card-sol {
    margin-left: 1em !important;
  }
}
/* @media screen and (max-width: 350px) { */
@media screen and (width<= 350px) {
  .overview .main-title {
    font-size: 1.7em;
    margin-bottom: 0;
    margin-top: 0;
  }
  .sol-title-desc {
    font-size: 0.8em;
  }
  .main-title-truliacare {
    font-size: 1.7em;
  }
  .solution-feature-desc {
    font-size: 0.8em;
  }
}
.post-box {
  width: 300px;
  border: 1px solid #e1e8ed;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  margin: 20px auto;
}
.post-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f5f8fa;
  border-bottom: 1px solid #e1e8ed;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.post-info {
  margin-left: 10px;
}
.post-info strong {
  display: block;
  font-size: 16px;
  color: #000;
}

.post-info span {
  display: block;
  color: #000;
  font-size: 12px;
}

.post-content {
  padding: 20px;
  text-align: center;
}

.timestamp {
  display: block;
  color: #657786;
  font-size: 12px;
  margin-top: 10px;
}

.view-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1da1f2;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

@media (max-width: 430px) {
  .download-modal {
    width: 90%;
    padding: 1em;
  }
  .download-modal-heading {
    font-size: 1em;
  }
  .download-modal-button {
    font-size: 1em;
  }
}

/* Screen width: 375px */
@media (min-width: 321px) and (max-width: 375px) {
  .download-modal {
    width: 85%;
    padding: 1em;
  }
  .download-modal-heading {
    font-size: 1em;
  }
  .download-modal-button {
    font-size: 1em;
  }
}
/* Screen width: 425px */
@media (min-width: 376px) and (max-width: 425px) {
  .download-modal {
    width: 80%;
    padding: 1em;
  }
  .download-modal-heading {
    font-size: 1.2em;
  }
  .download-modal-button {
    font-size: 1.2em;
  }
}

/* Screen width: 768px */
@media (min-width: 426px) and (max-width: 768px) {
  .download-modal {
    width: 60%;
    padding: 1em;
  }
  .download-modal-heading {
    font-size: 1em;
  }
  .download-modal-button {
    font-size: 1.2em;
  }
}
