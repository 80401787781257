.feature-section {
  padding: 10px 0 10px;
}

.feature-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 985px;
  padding-right: 15px;
  padding-left: 15px;
}
#feature {
  padding-top: 70px;
  margin-top: -70px;
}
.feature-title {
  position: relative;
  min-height: 1px;
  padding-left: 0px;
  padding-right: 55px;
}
.feature-title-fold2 {
  position: relative;
  min-height: 1px;
  padding-left: 10%;
  padding-right: 0px;
  padding-top: 2%;
}
.feature-title h3 {
  text-align: left;
  font-size: 30px;
  margin-top: 20px;
}
.feature-title-fold2 h2 {
  text-align: left;
  /* font-size: 30px; */
  margin-top: 20px;
}
.feature-desc {
  text-align: justify;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 100;
  color: black;
  letter-spacing: 0.3px;
}
.feature-box {
  padding: 0 0 0 0;
  margin-bottom: 0px;
  font-size: 14px;
}
.feature-box ul {
  margin-left: -0.5em;
}
.feature-box li {
  text-align: left;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 100;
  color: black;
}
.feature-figure {
  position: relative;
  min-height: 1px;
  padding-left: 10%;
  padding-right: 0px;
  padding-top: 2%;
}
.feature-item-container {
  float: left;
  position: relative;
}
.feature-image {
  width: 80%;
  max-width: 100%;
  max-height: 410px;
  transition-duration: 0.4s;
}
.special-feature-image {
  width: 100%;
  max-width: 100%;
  max-height: 410px;
  transition-duration: 0.4s;
}
.feature-image:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 -5px 5px 0 rgba(0, 0, 0, 0.19),
    5px 0 5px 0 rgba(0, 0, 0, 0.19), -5px 0 5px 0 rgba(0, 0, 0, 0.19);
}
.highlighted-bullet {
  padding: 2% 1%;
  /* margin-top: 2%; */
  /* padding: 4px 0px; */
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
  /* display: flex; */
  /* justify-content: space-around; */
}
.highlighted-bullet ul {
  margin: 0;
  /* padding-left: 3.%; */
  /* padding-left: ; */
}
.highletd-bullet li {
  font-size: 8px;
  text-align: left;
  /* line-height: 1.6em; */
}
.highlighted-bullet li {
  font-size: 14px;
  color: black;
  font-family: "Open Sans";
  font-weight: 500;
  /* letter-spacing: 0.5px;
  line-height: 20px; */
  width: 50%;
}
.full-feature-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  animation: fadeIn 0.3s ease-in-out;
}
.full-screen-image {
  max-width: 90%;
  max-height: 90%;
  cursor: pointer;
  animation: zoomIn 0.3s ease-in-out;
}
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

/* Keyframes for zoom-in animation */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

/* Keyframes for zoom-out animation */
@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}
@media (min-width: 768px) {
  .feature-title {
    padding-right: 10px;
    width: 50%;
  }
  .highlighted-bullet li {
    letter-spacing: 0.3px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 560px) {
  .feature-title {
    float: left;
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .feature-title-fold2 {
    float: left;
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .feature-title h2 {
    text-align: center;
    /* font-size: 26px; */
    margin-top: 10px;
  }
  .feature-title-fold2 h2 {
    text-align: center;
  }
  .feature-figure {
    padding-left: 24%;
    padding-right: 20%;
    text-align: center;
    margin-top: 0.5em;
  }
  .feature-desc {
    letter-spacing: -0.1px;
    margin-bottom: 0.5em;
  }
  .sub-list {
    flex-direction: column;
  }
  .highlighted-bullet li {
    width: 100%;
    font-size: 12px;
    letter-spacing: -0.3px;
  }
}
