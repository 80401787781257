.careers-title-wrapper {
  display: block;
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.career-main-title {
  font-weight: 300;
  font-size: 2.5em;
  color: #333;
  margin: 30px 0 25px 0;
  padding-top: 0px;
  line-height: 1em;
}
.career-sub-title {
  margin-left: 10px;
  margin-right: 10px;
  text-align: justify;
  line-height: 1.5em;
  font-weight: 100;
  font-size: 18px;
  color: #000;
  margin-bottom: 1em;
  padding-top: 10px;
}
#openings-wrapper {
  width: 100%;

  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.opening-title {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.opening-title h2 {
  line-height: 1em;
  font-weight: 100;
  margin: 30px 0 25px 0;
  text-rendering: optimizelegibility;
  padding-top: 0px;
}
#tabContainer {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tab {
  padding: 12px 20px;
  text-align: center;
  cursor: pointer;
  color: #000 !important;
  font-weight: 300;
  background-color: #f8f9fa;
  border: 1px solid transparent;
  border-radius: 0.25rem 0.25rem 0 0;
  transition: background-color 0.3s ease;
  flex: 1;
}

.tab:hover {
  /* background-color: #e9ecef; */
  color: #000 !important;
}

.activeTab {
  background-color: #a4c639;
  color: #000 !important ;
  border-color: #ddd #ddd white;
}

#arsenalWindow {
  /* border: 1px solid #ddd; */
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 20px;
  /* background-color: #eee; */
}

/* PositionBox */
.positionBox {
  background-color: #f8f8f8;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}
.positionBox.altBackground {
  background-color: #f8f8f8; /* White background for alternating items */
}

.positionBox h3 {
  font-size: 22px;
  color: #343a40;
  margin-bottom: 10px;
}

.positionBox p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: #000;
}

.positionBox ul {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.positionBox ul li {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.knowMore {
  font-weight: bold;
  text-decoration: underline;
}
.positionArsenal {
  display: block;
  position: relative;
  padding-top: 25px;
  padding-bottom: 1px;
}
.positiontitleWrapper {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.position-subTitle {
  text-align: justify;
  /* font-family: OpenSans, Arial, Helvetica, sans-serif; */
  line-height: 1.5em;
  font-weight: 100;
  color: #999;
  margin-bottom: 1em;
  padding-top: 10px;
}
#career-overview {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  text-align: left;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
}

/* Title Styling */
.opening-title h2 {
  font-size: 2.25em;
  margin-top: 35px;
  margin-bottom: 10px;
}

.opening-title ul {
  list-style: none;
}

.opening-title ul li {
  font-size: 1em;
  /* margin-bottom: 10px; */
}

.career-tabs {
  max-width: 985px;
  margin-left: auto;
  margin-right: auto;
}

/* lifestyle css */

.lifeAtKratinSection {
  display: flex;
  justify-content: space-between;
  background: #fffafa;
  margin-top: 5rem !important;
  margin-right: 2px !important;
  margin-bottom: 1rem;
}
.lifeAtKratinSection .col-2 {
  flex: 1;
  max-width: 16.6667%;
  padding: 0 10px;
  box-sizing: border-box;
}
.lifeAtKratin {
  width: 100%;
  height: 7rem;
  /* height: auto; */
}
.lifeAtStyle {
  margin-top: -5.7rem;
  margin-left: 50px;
  color: #fff;
  font-weight: 700;
  font-size: 29px;
}
.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerIcons {
  width: 50%;
  max-width: 50px;
  margin-bottom: 5px;
}
.footerIconDesc {
  color: #4f8fb2;
  font-size: 17px;
  line-height: 1.5rem;
  text-align: center;
}

.ownershipIconAdj {
  width: 26% !important;
}
@media screen and (max-width: 1200px) {
  /* .lifeAtKratin {
    width: 100%;
    height: 7.5rem;
  }
  .lifeAtStyle {
    margin-top: -5.7rem;
    margin-left: 24px;
    font-size: 26px;
  }
  .footerIcons {
    width: 25%;
    margin-bottom: 10px;
  }
  .footerIconDesc {
    font-size: 14px;
  } */
  .lifeAtStyle {
    margin-top: -5.7rem;
    margin-left: 24px;
    font-size: 26px;
  }
  .footerIcons {
    max-width: 40px; /* Reduce icon size */
  }
  .footerIconDesc {
    font-size: 12px; /* Reduce text size */
  }
}
@media screen and (max-width: 992px) {
  .lifeAtStyle {
    margin-left: 30px;
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .careers-title-wrapper {
    margin-left: 25px;
    margin-right: 25px;
  }
  .tab {
    font-size: 14px;
    padding: 10px;
  }

  #arsenalWindow {
    padding: 15px;
  }

  .positionBox h3 {
    font-size: 20px;
  }

  .positionBox p,
  .positionBox ul li {
    font-size: 14px;
  }
  #career-overview {
    margin-left: 5px;
    margin-right: 5px;
  }

  .opening-title h2 {
    font-size: 1.75em;
    margin-top: 25px;
    margin-bottom: 8px;
  }

  .opening-title ul {
    margin-left: -10px;
  }

  .opening-title ul li {
    font-size: 1em;
    margin-bottom: 8px;
  }

  .lifeAtKratinSection {
    margin-top: 8rem !important;
  }
  .lifeAtStyle {
    margin-left: 20px;
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .tab {
    font-size: 12px;
    padding: 8px;
  }

  .positionBox h3 {
    font-size: 18px;
  }

  .positionBox p,
  .positionBox ul li {
    font-size: 12px;
  }
  #career-overview {
    margin-left: 5px;
    margin-right: 5px;
  }

  .opening-title h2 {
    font-size: 1.5em !important;
    margin-top: 20px !important;
    margin-bottom: 6px !important;
  }

  .opening-title ul {
    margin-left: -5px;
  }

  .opening-title ul li {
    font-size: 0.9em;
    margin-bottom: 6px;
  }
  .lifeAtStyle {
    margin-left: 16px;
    font-size: 17px;
  }
  .footerIcons {
    width: 30%;
    margin-bottom: 10px;
  }
  .footerIconDesc {
    font-size: 13px;
  }
  .ownershipIconAdj {
    width: 26% !important;
  }
  .approcchIconAdj {
    margin-top: 2px;
  }
  .flexibilityIconAdj {
    margin-top: 2px;
  }
}
@media screen and (max-width: 479px) {
  .lifeAtKratin {
    height: 8rem;
  }
  .lifeAtStyle {
    margin-left: 3px;
    font-size: 13px;
  }
  .footerIcons {
    width: 50%;
    margin-bottom: 10px;
  }
  .footerIconDesc {
    font-size: 11px;
  }
}

@media screen and (max-width: 380px) {
  .lifeAtStyle {
    margin-left: 11px;
    font-size: 12px;
  }
  .footerIconDesc {
    font-size: 10px;
    line-height: 1.5rem;
  }
}
