.awards-section {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 985px;
}
.awards-main-title {
  font-size: 2.5em;
  color: #282828;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 1em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  padding-top: 0;
}
.entry {
  text-align: center;
  width: 32.7%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  max-height: 395px;
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 20px;
  padding-right: 20px;
}
.award-image {
  border-radius: 6px 6px 0 0;
  max-height: 130px;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.entry img {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  max-height: 135px;
  margin-top: -2px;
}
.award-body {
  background: #ffffff;
  box-shadow: 0 -3px rgba(0, 0, 0, 0.1) inset;
  border-radius: 0 0 3px 3px;
  margin: 0;
  padding: 20px;
  text-align: center;
  -webkit-backface-visibility: hidden;
}
.award-title {
  color: #666;
  font-size: 16px;
  margin: 10px 0 5px;
  letter-spacing: 0.2px;
  font-weight: 500;
  /* font-family: inherit; */
  line-height: 16px;
  margin-bottom: 1em;
}
@media only screen and (max-width: 767px) {
  .entry {
    width: 100%;
    margin-bottom: 1.5em;
  }
}
@media screen and (max-width: 350px) {
  .awards-main-title {
    font-size: 1.7em;
  }
  .award-title {
    font-size: 0.8em;
  }
}
