.blogs-overview {
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.blogs-title-wrapper {
  max-width: 985px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  justify-content: center;
}
.blogs-title {
  margin-top: 20px;
  margin-bottom: 20px;
}
.blogs-title h2 {
  font-size: 2.5rem;
  color: #333;
  font-weight: 100;
}
.blog-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blog-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 0px solid #ddd;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #fff;
}
.blog-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.blog-card-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.blog-card-image {
  width: 30%;
  height: auto;
  object-fit: cover;
}

.blog-card-body {
  width: 70%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog-card-heading {
  color: #333;
  font-weight:300;
  cursor: pointer;
  font-size: 1.8em !important;
}
.blog-card-heading:hover {
  color: #007bff;
}

.blog-description {
  font-size: 18px;
  font-weight: 100;
  text-align: justify;
  line-height: 22px;
  /* letter-spacing: 0.1px; */
  letter-spacing: -.1px;
  line-height: 1.5em;
  font-family: "Open Sans";
}

.read-more-toggle {
  margin-left: 5px;
  color: #007bff;
  cursor: pointer;
}
.blog-date {
  font-size: 0.7em;
}

.blog-tags {
  font-size: 0.8rem;
  color: #333;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.loading__dot {
  display: inline-block;
  height: 8px;
  margin: 0 5px;
  width: 8px;
  border-radius: 50%;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  animation: wave 1.5s infinite ease-in-out;
}

.loading__dot:nth-child(1) {
  animation-delay: 0s;
}
.loading__dot:nth-child(2) {
  animation-delay: 0.3s;
}
.loading__dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-8px);
  }
}

@media (max-width: 768px) {
  .blog-card {
    flex-direction: column;
  }

  .blog-card-image {
    max-height: auto;
  }
  .blog-card-body h6 {
    font-size: 10px;
  }

  .blog-card-body {
    width: 100%;
  }
  .blog-description {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 480px) {
  .blogs-overview {
    padding: 1rem;
  }
  .blog-card-content {
    display: flex;
    flex-direction: column;
  }
  .blog-card-image {
    width: 100%;

    max-height: 50%;
  }
  .blog-card-body {
    padding: 0.75rem;
  }
}
