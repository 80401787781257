.privacy-wrapper {
  max-width: 985px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-left: 20px;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
strong {
  font-weight: 700;
}
