.footer-container {
  background-color: #808285;
  color: white !important;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 417px;
  padding-top: 25px;
  margin-top: 115px;
  overflow: hidden;
}

#footer {
  width: 100%;
  max-width: 985px;
  margin: 0 auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}

.footer-about {
  width: 60%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}
.footer-connect {
  width: 40%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}

#footer h2 {
  font-size: 48px;
  font-weight: 100;
  line-height: 1em;
  text-rendering: optimizeLegibility;
  margin: 0 0 5px 0;
  font-family: inherit;
  color: inherit;
}

.footer-about p {
  margin-left: 0;
  padding-right: 10px;
}

.footer-connect h2 {
  margin-bottom: 1em;
}

.perspectives {
  margin-left: 10px;
}

.socials {
  padding: 0;
  margin-left: 10px;
}

.social-icon {
  background-image: url("../assets/homepage/social.png");
  width: 50px;
  height: 50px;
  overflow: hidden;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 1em;
  list-style: none;
  padding-left: 0px;
  background-size: 200px;
  background-repeat: no-repeat;
  cursor: pointer;
}

#socialLinkedIn {
  background-position: -50px -50px;
}

#socialTwitter {
  background-position: -100px -50px;
}

#socialYoutube {
  background-position: -150px -50px;
}

#socialFacebook:hover {
  background-position: 0px 0px;
}

#socialTwitter:hover {
  background-position: -100px 0px;
}

#socialLinkedIn:hover {
  background-position: -50px 0px;
}

#socialYoutube:hover {
  background-position: -150px 0px;
}

.twitter-feed {
  display: inline-block;
  max-width: 350px;
  vertical-align: middle;
  margin-bottom: 65px;
  width: 100%;
  padding-top: 10px;
  margin-top: 20px;
}

.twitter-timeline {
  text-decoration: none;
  color: #ffffff;
}

.footer-nav {
  position: relative;
  width: 100%;
  bottom: 25px;
  left: 0px;
  margin-top: 45px;
  text-align: center;
}

.footer-nav-list {
  width: auto;
  border-top: 1px solid #737578;
  padding: 25px 0 0;
  margin: 0;
}

.footer-nav-item {
  border: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 1em;
  font-size: 18px;
  list-style: none;
  line-height: 1em;
  padding-left: 0px;
}

.footer-nav-list a {
  color: #ffffff;
}

.footer-nav-item a.active {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .footer-about {
    width: 100%;
    text-align: left;
  }
  .footer-connect {
    width: 100%;
    text-align: center;
  }

  .footer-nav {
    text-align: center;
  }
  #footer h2 {
    font-size: 41px;
  }
}

@media screen and (max-width: 350px) {
  .footer-about p {
    font-size: 0.8em;
  }
}
