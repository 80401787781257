.contact-us {
  margin-top: 40px;
}
.contact-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 985px;
}
#contact {
  padding-top: 70px;
  margin-top: -70px;
}
.contact-main-title {
  font-size: 2.5em !important;
  color: #282828;
  text-align: left !important;
  font-family: "Open Sans";
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 25px;
  margin-bottom: 27px;
  line-height: 1em;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  padding-top: 0;
}
#contactPart1 {
  width: 98%;
  display: inline-block;
  position: relative;
  max-width: 983px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.office-contact {
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 48%;
  border-radius: 8px;
  text-align: left;
  min-width: 240px;
  vertical-align: top;
  margin-bottom: 15px;
}
.phn-number {
  font-weight: 300;
}
.phn-number:hover {
  color: #0179cc;
}
.office-contact h3 {
  font-size: 40px;
  font-weight: 100;
  line-height: 1.4em;
  margin: 0 0 0.2em 0;
  margin-left: 25px;
  padding-top: 10px;
}
.office-contact-inner {
  position: relative;
  margin-right: 5px;
  top: auto !important;
  overflow-y: hidden !important;
  display: inline-block;
  left: 30px !important;
  width: 90%;
  height: auto;
  margin-top: -7px;
  font-weight: 400;
}
.office-contact-inner p {
  font-size: 16px;
  line-height: 22px;
  background: rgba(255, 255, 255, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.office-contact-inner a {
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}
.contact-btn {
  background-color: #0179cc;
  font-size: 16px;
  padding: 10px;
  color: white !important;
  margin-top: 15px;
  display: inline-block;
  margin-right: 25px;
  font-weight: 400;
  text-align: center;
  height: auto;
  border: none;
  letter-spacing: -0.4px;
}
@media screen and (max-width: 768px) {
  .office-contact-inner p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (max-width: 350px) {
  .contact-main-title {
    font-size: 1.7em;
  }
  .office-contact h3 {
    font-size: 30px;
  }
  .office-contact-inner p {
    font-size: 12px;
  }
}
