.blogDetails-wrapper {
  max-width: 985px;
  margin-right: auto !important;
  margin-left: auto !important;
}
.blog-detail-image {
  width: 100%;
}
.blog-details-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.blog-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 15px;
}
.blog-back-icon span{
color: #333;
font-family: 'Open Sans';
}
.blogDetails-date {
  font-size: 0.5em;
  margin-top: 5px;
  font-weight: 100;
}
.blogDetails-wrapper {
  margin: 0 auto;
  padding: 20px;
}
.route {
  font-weight: 300;
  font-size: 18px;
}
.blog-details-card{
    display: flex;
    flex-direction: row;
    border: none;
    overflow: hidden;
}

.blog-card-body {
  padding: 20px;
}

.blog-card-title {
  font-size: 2.3rem;
  font-weight: 300;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.blog-detail-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-card-desc {
  font-size: 18px;
  line-height: 2;
  color: #000;
}

@media (max-width: 768px) {
  .blog-card-title {
    font-size: 1.5rem;
  }
}
