.care-coordination-overview {
  margin: 0px;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
}
.coordination-title-wrapper {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.care-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 985px;
  margin: 0 auto;
}
.care-coordination-title {
  font-family: "Open Sans";
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 300;
  color: #333;
  margin-bottom: 20px;
  text-rendering: optimizelegibility;
  padding-top: 0px;
  margin-top: 15px;
}
.care-coordination-subtitle {
  text-align: justify;
  font-size: 18px;
  visibility: visible;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  font-family: "Open Sans";
  line-height: 1.5em;
  font-weight: 100;
  color: #000000;
  letter-spacing: calc(18px * -0.02);
  margin-bottom: 1em;
  padding-top: 10px;
}
.care-video {
  padding-left: 0px;
  padding-right: 0px;
  visibility: visible;
  margin-top: 2%;
}
.coordination-container {
  float: left;
  position: relative;

  margin-left: 3em;
  margin-top: 1em;
}
.hospice-card img {
  max-height: 35px;
  max-width: 40px;
}
.innovation {
  padding-top: 15px;
  padding-right: 0px;
}
.innovation a {
  text-decoration: none;
  color: #428bca;
}
.innovation-image {
  width: 100%;
  max-width: 100%;
  /* height: 200px; */
  cursor: pointer;
  /* -webkit-transition-duration: 0.4s; */
  /* Safari */
  transition-duration: 0.4s;
  /* height: 10%; */
}
.innovation-image:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 -5px 5px 0 rgba(0, 0, 0, 0.19),
    5px 0 5px 0 rgba(0, 0, 0, 0.19), -5px 0 5px 0 rgba(0, 0, 0, 0.19);
}

.main-title-hospice {
  font-family: Open Sans;
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 300;
  color: #333;
  margin-bottom: 20px;
  text-rendering: optimizelegibility;
  padding-top: 0px;
  margin-top: 15px;
}
@media screen and (max-width: 770px) {
  .coordination-title-wrapper {
    max-width: 985px;
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .care-container {
    width: 750px;
  }
  .yt-link {
    width: 40%;
  }
  .care-coordination-title {
    font-size: 2.3em;
  }
}
@media only screen and (min-width: 560px) and (max-width: 991px) {
  .care-video {
    width: 40%;
  }
}
@media only screen and (max-width: 767px) {
  /* .care-coordination-overview {
    top: 28px;
  } */

  .care-coordination-subtitle {
    max-width: max-content;
  }
  .care-video {
    /* margin-top: 5%; */
    width: 80%;
    margin-left: 2em;
  }
}
@media (orientation: portrait) {
  .care-coordination-title {
    font-size: 2em !important;
    line-height: 1em;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 320px) and (max-width: 750px) {
  .coordination-title-wrapper {
    margin-top: 20px;
  }
  .coordination-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .play iframe {
    width: 100%;
  }
  .card-container {
    margin-top: 10px !important;
  }
  .main-title-hospice {
    font-size: 2em;
  }
  .innovation {
    width: 100%;
  }
}
