.card-container {
  margin-top: 40px;
  max-width: 985px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; /* Center cards when fewer than 3 */
}

.card-button {
  background-color: white;
  color: #0179cc;
  font-weight: bold;
  font-size: 0.89em;
  text-align: center;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 -2px 2px 0 rgba(0, 0, 0, 0.19);
  padding: 16px 32px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
  flex: 1 1 calc(33.333% - 20px); /* 3 cards per row minus gap */
}

.card-button:hover {
  background-color: rgba(220, 220, 220, 0.8);
}

.card-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.maintain {
  max-width: 51px;
  max-height: 51px;
  margin-right: 15px; /* Space between image and text */
}

.card-desc {
  font-weight: 500;
  color: black;
  flex: 1;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 1220px) {
  .card-button {
    flex: 1 1 calc(33.333% - 20px);
  }
}

@media (max-width: 1024px) {
  .card-button {
    flex: 1 1 calc(33.333% - 2px);
  }

  .maintain {
    max-width: 40px;
    max-height: 40px;
  }

  /* .card-desc {
    font-size: 0.8em;
  } */
}
@media (max-width: 768px) {
  .card-button {
    flex-basis: calc(50% - 2px);
  }
}

@media (max-width: 425px) {
  .card-button {
    flex: 1 1 100%; /* 1 card per row */
    font-size: 1em;
  }

  .maintain {
    max-width: 35px;
    max-height: 35px;
  }

  .card-desc {
    font-size: 0.9em;
    letter-spacing: -0.6px;
  }
}
