.impact-container {
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
#about {
  padding-top: 70px;
}

.title-font {
  margin-top: 40px;
  line-height: 1.2em;
  /* font-weight: 400; */
  text-rendering: optimizeLegibility;
  font-size: 2.5em;
  color: #282828;
  text-align: left;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 27px;
}
.stat {
  margin: auto;
  padding: 3% 0;
  width: 100%;
  padding-top: 0;
}
.stat > div {
  width: 24%;
  font-size: 0.8rem;
  margin: auto;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 3% 0;
}
.descriptionStat {
  width: 100%;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: row;
  padding: 4% 2%;
}
.number {
  width: 100%;
  text-align: center;
  font-size: 45px;
  color: black;
  display: block;
  padding: 5% 6%;
  align-content: left;
  flex-direction: row;
  justify-content: space-between;
}
.num1 {
  display: inline-flex;
  margin-right: 0.2em;
}
.num {
  display: inline-flex;
}
.sDetails {
  text-align: center;
  /* font-size: 1.5rem; */
  font-size: 15px;
  font-weight: 300;
  color: black;
  display: block;
  padding: 2% 9%;
  line-height: 1.5;
  padding-top: 0;
  width: 9 5%;
}
@media screen and (min-width: 992px) and (max-width: 1088px) {
  .stat {
    margin: auto;
  }
  /* .descriptionStat {
    width: 70%;
  } */
  .number {
    font-size: 35px;
  }
  /* .sDetails {
    font-size: 10px;
  } */
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .stat {
    margin: auto;
  }
  .number {
    font-size: 30px;
  }
  .sDetails {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .stat {
  }
}

@media screen and (max-width: 767px) {
  .stat {
    margin-right: 0;
    margin-left: 0 !important;
    flex-direction: column;
  }
  .stat > div {
    width: 46%;
    margin-left: 2%;
  }
  /* .descriptionStat {
    width: 95%;
  } */
  .number {
    font-size: 30px;
  }
  .sDetails {
    font-size: 12px;
  }
}
@media screen and (max-width: 350px) {
  .title-font {
    font-size: 1.7em;
  }
}
