.alabaster {
  /* max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto; */
}
#innovation {
  padding-top: 70px;
  margin-top: -70px;
}
.container-innovation {
  padding-right: 15px;
  padding-left: 15px;
  max-width: 985px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 0 auto;
}

.innovation-main-title {
  padding-bottom: 0;
  font-size: 2.25em;
  font-family: "Open Sans";
  font-weight: 300;
  color: #333;
  text-rendering: optimizeLegibility;
  padding-top: 0;
}

.featured-box {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.1px;
  text-align: left;
}
.read-more {
  color: #016cb5;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  transition-duration: 0.4s;
  letter-spacing: calc(16px-* 0.02);
  -webkit-transition-duration: 0.4s;
}
.read-more:hover {
  font-size: 17px;
}
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  animation: fadeIn 0.3s ease-in-out;
}
.fullscreen-image {
  max-width: 90%;
  max-height: 50%;
  cursor: pointer;
  animation: zoomIn 0.3s ease-in-out;
}
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

/* Keyframes for zoom-in animation */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}

/* Keyframes for zoom-out animation */
@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 768px) {
  .featured-box {
    letter-spacing: 0.4px;
  }
}
@media screen and (max-width: 479px) {
  .innovation-main-title {
    font-size: 2.5em;
  }
  .featured-box {
    margin-left: 6px;
    margin-right: 6px;
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  .innovation-main-title {
    font-size: 1.7em;
  }
  .featured-box {
    font-size: 0.8em;
  }
}
