.ant-modal-content {
  padding: 0em 0px !important;
  background-color: #0179cc;
}

.demoModal {
  border-radius: 0px;
  display: flex;
}
.powered {
  font-size: 13px;
  margin: 0;
}
.demo-icon {
  width: 60%;
}
.greetings-text {
  margin-top: 50px;
  color: #fff;
  font-size: 20px;
}
.heading {
  font-size: 30px;
  margin-top: 30%;
  font-weight: 700;
  line-height: 1px;
}
.greetings-desc {
  margin-top: 30px;
  font-size: 16px;
}
.greetings-footer {
  margin-top: 10px;
  font-size: 14px;
}
.details-card {
  background-color: #ffffff;
  border-radius: 4px;
  display: block;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.align-logo {
  padding-top: 1.5em;
  margin-left: 0;
  margin-right: 0;
}

.user-circle {
  height: 3em;
  width: 3em;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
  margin: auto;
}
.form-header {
  font-size: 20px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
}
.form-text {
  text-align: center;
  font-size: 15px;
  line-height: 1.2;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 5px;
}
.demo-user-icon {
  display: flex;
  justify-content: center;
}
