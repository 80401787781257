.carousel_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.loading__dot {
  display: inline-block;
  height: 8px;
  margin: 0 5px;
  width: 8px;
  border-radius: 50%;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  animation: wave 1.5s infinite ease-in-out;
}

.loading__dot:nth-child(1) {
  animation-delay: 0s;
}
.loading__dot:nth-child(2) {
  animation-delay: 0.3s;
}
.loading__dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-8px);
  }
}
