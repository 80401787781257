.career-container {
  width: 100%;
}
.dicom-title-wrapper {
  display: block;
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.dicom-title-header {
  font-size: 3em;
  font-family: "Open Sans";
  line-height: 1em;
  font-weight: 300;
  color: #333;
  margin: 30px 0 25px 0;
  text-rendering: optimizelegibility;
  padding-top: 0px;
}
.carrer-desc {
  margin-left: 10px;
  margin-right: 10px;
  text-align: justify;
  font-family: "Open Sans";
  line-height: 1.6em;
  font-weight: 100;
  color: #000;
  margin-bottom: 1em;
  padding-top: 10px;
  /* margin: 0 0 10px; */
  letter-spacing: -0.5px;
}
.column {
  float: left;
  width: 33.33%;
  padding: 6px;
  transition: 0.6s;
  border: 1px solid transparent;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.career-title-wrapper {
  display: block;
  max-width: 985px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.main-carrer-title {
  font-size: 3em;
  font-family: "Open Sans";
  line-height: 1em;
  font-weight: 300;
  color: #333;
  margin: 30px 0 25px 0;
  text-rendering: optimizelegibility;
  padding-top: 0px;
  margin-bottom: 1em;
}
.slideShow-container {
  position: relative;
  background-color: #f1f1f1f1;
}
.slides {
  display: block;
  padding: 80px;
  text-align: center;
}
.slides q {
  font-style: italic;
}
.author {
  color: cornflowerblue;
}

@media screen and (max-width: 770px) {
  .dicom-title-wrapper {
    max-width: 985px;
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
  }
  .career-title-wrapper {
    max-width: 985px;
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
  }
  .dicom-title-wrapper h2 {
    font-size: 2.1em;
  }
  .slides {
    padding: 30px;
  }
  .slides q {
    letter-spacing: 0.3px;
  }
  .main-carrer-title {
    font-size: 2.25em;
  }
}
